<template>
	<div>
		<!--顶部工具条-->
		<el-col :span="24">
			<el-form :inline="true">
				<el-form-item label="关键字:">
					<el-input v-model="filters.searchKey" @input="(e) => (filters.searchKey = utils.validForbid(e))" placeholder="请输入合同编号/业主/开票抬头/开票税号" clearable @clear='clearContent'></el-input>
				</el-form-item>
				<el-form-item>
					<toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
				</el-form-item>
			</el-form>
		</el-col>
		<!--列表-->
		<el-table
			:data="dataPer"
			highlight-current-row 
			@current-change="selectCurrentRow"
			v-loading="listLoading"
			@selection-change="selsChange"
			style="width: 100%;"
		>
			<!-- <el-table-column type="selection" width="50">
			</el-table-column> -->
			<el-table-column type="index" width="80" align="center" :index='indexMethod'></el-table-column>
			<el-table-column prop="contractNo" label="合同编号" width="" align="center"></el-table-column>
			<el-table-column prop="contractStartDay" label="履约起始时间" width="" align="center"></el-table-column>
			<el-table-column prop="contractPeriod" label="合同周期(年)" width="" align="center"></el-table-column>
            <el-table-column prop="customerName" label="业主" width="" align="center"></el-table-column>
            <el-table-column label="联系人" width="" align="center">
                <template slot-scope="scope">
                    <el-button @click.native="showExternalPerson(scope.row)" type="text">查看</el-button>
                </template>
            </el-table-column>
            <el-table-column label="单价(元)" width="" align="center">
                <template slot-scope="scope">
                    <el-form v-if="scope.row.ddIdPriceType == 10">
                        <el-form-item label="综合单价：">
                            <div>{{scope.row.unitPrice}}</div>
                        </el-form-item>
                    </el-form>
                    <el-form v-if="scope.row.ddIdPriceType == 11">
                        <el-form-item label="特殊建筑：">
                            <div>{{scope.row.diffSpecPrice}}</div>
                        </el-form-item>
                        <el-form-item label="普通建筑：">
                            <div>{{scope.row.diffCommonPrice}}</div>
                        </el-form-item>
                        <el-form-item label="改造项目：">
                            <div>{{scope.row.diffRebuildPrice}}</div>
                        </el-form-item>
                    </el-form>
                </template>
            </el-table-column>
            <el-table-column label="结算周期" width="" align="center">
                <template slot-scope="scope">
                    <span>{{scope.row.settlementPeriod}}个月</span>
                </template>
            </el-table-column>
            <el-table-column label="开票信息" width="" align="center">
                <template slot-scope="scope">
                    <el-button @click.native="showInvoiceInfo(scope.row)" type="text">查看</el-button>
                </template>
            </el-table-column>
            <el-table-column label="申领者比例" width="" align="center">
                <template slot-scope="scope">
                    <el-button @click.native="showApplyRate(scope.row)" type="text">{{scope.row.applyRate}}%</el-button>
                </template>
            </el-table-column>
            <el-table-column label="协作者比例" width="" align="center">
                <template slot-scope="scope">
                    <el-button @click.native="showAssociateRate(scope.row)" type="text">{{scope.row.associateRate}}%</el-button>
                </template>
            </el-table-column>
		</el-table>
        <!--底部工具条-->
        <el-col :span="24" class="pageBar">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pages.pageIndex"
                :page-sizes="pages.pageArr"
                :page-size="pages.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="pages.dataCount"
            >
            </el-pagination>
        </el-col>
                <!--查看-->
        <el-dialog
        title="开票信息"
        :visible.sync="invoiceVisible"
        v-model="invoiceVisible"
        :close-on-click-modal="false"
        >
            <el-form label-width="150px">
                <el-form-item label="名称：">
                    <div>{{currentRow.invoiceTitle}}</div>
                </el-form-item>
                <el-form-item label="纳税人识别号：">
                    <div>{{currentRow.invoiceFax}}</div>
                </el-form-item>
                <el-form-item label="银行账户：">
                    <div>{{currentRow.invoiceBankAccount}}</div>
                </el-form-item>
                <el-form-item label="开户行：">
                    <div>{{currentRow.invoiceBankName}}</div>
                </el-form-item>
                <el-form-item label="地址：">
                    <div>{{currentRow.invoiceAddress}}</div>
                </el-form-item>
                <el-form-item label="电话：">
                    <div>{{currentRow.invoicePhone}}</div>
                </el-form-item>
                <!-- <el-form-item label="邮编：">
                    <div>{{currentRow.invoiceTitle}}</div>
                </el-form-item> -->
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="invoiceVisible = false">关闭</el-button>
            </div>
        </el-dialog>
        <el-dialog
        title="联系人信息"
        :visible.sync="externalPersonVisible"
        v-model="externalPersonVisible"
        :close-on-click-modal="false"
        >
            <el-table
            :data="dataExternalPersons"
            highlight-current-row 
            v-loading="listLoading"
            style="width: 100%;"
        >
            <el-table-column prop="name" label="姓名" width="" ></el-table-column>
            <el-table-column prop="phone" label="手机号" width="" ></el-table-column>
            <el-table-column prop="positionTypeName" label="人员岗位" width="" ></el-table-column>
            <el-table-column prop="positionTagName" label="职位标签" width="" ></el-table-column>
        </el-table>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="externalPersonVisible = false">关闭</el-button>
            </div>
        </el-dialog>
        <el-dialog
        title="申领者信息"
        :visible.sync="applyRatioVisible"
        v-model="applyRatioVisible"
        :close-on-click-modal="false"
        >
            <el-table
            :data="dataApply"
            highlight-current-row 
            v-loading="listLoading"
            style="width: 100%;"
        >
            <el-table-column prop="name" label="姓名" width="" ></el-table-column>
            <el-table-column prop="phoneNumber" label="手机号" width="" ></el-table-column>
            <el-table-column prop="rate" label="分成比例" width="" >
                <template slot-scope="scope">
                    <span>{{scope.row.rate}}%</span>
                </template>
            </el-table-column>
        </el-table>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="applyRatioVisible = false">关闭</el-button>
            </div>
        </el-dialog>
        <el-dialog
            title="协作者信息"
            :visible.sync="assistanceRatioVisible"
            v-model="assistanceRatioVisible"
            :close-on-click-modal="false"
        >
            <el-table
                :data="dataAssistance"
                highlight-current-row 
                v-loading="listLoading"
                style="width: 100%;"
                empty-text="无协作者"
            >
                <el-table-column prop="name" label="姓名" width="" ></el-table-column>
                <el-table-column prop="phoneNumber" label="手机号" width="" ></el-table-column>
                <el-table-column prop="rate" label="分成比例" width="" ></el-table-column>
            </el-table>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="assistanceRatioVisible = false">关闭</el-button>
            </div>
        </el-dialog>

	</div>
</template>
<script>
import util from '../../../util/date';
import {getDrContractList, getContractContactUser, getContractApplyUser, getContractCollaboratorUser} from '../../api/api';
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
import Qs from 'qs'
export default {
	components: { Toolbar },
	data() {
		return {
            utils: '',
			dataPer:[],
			listLoading: false,
			currentRow: {},
			//列表选中列
			sels: [],
			//增删改查按钮
			buttonList: [],
			//顶部筛选条件
			filters: { 
				name: ''
			},
			addLoading: false,
            pages: {  //关于分页的obj
                pageSize: 20,//默认每页条数
                pageArr: [10, 20, 30, 40],//默认每页条数选择
                pageIndex: 1, //默认进入页
                dataCount: 20, //默认总页数
            },
            viewsData:{},
            invoiceVisible:false,
            applyRatioVisible:false,
            assistanceRatioVisible:false,
            externalPersonVisible:false,
            dataExternalPersons:[],
            dataApply:[],
            dataAssistance:[],
		};
	},
	methods: {
		// 筛选框清空，重新获取数据
        clearContent(){
            this.getListData()
        },
		//当前行发生变化时
		selectCurrentRow(val) {
            if(val){
                this.currentRow = val;
            }
		},
		//选项发生变化时
		selsChange: function (sels) {
			this.sels = sels;
		},
        // 分页
        handleCurrentChange(val) {
            this.pages.pageIndex = val;
            this.getListData();
        },

        handleSizeChange(val){
            this.pages.pageSize = val
            this.getListData();
        },
        getListData(){
            this.listLoading = true;
            let params = {
                pageIndex: this.pages.pageIndex,
                pageSize: this.pages.pageSize,
            };
            if(this.filters.searchKey){
                params.searchKey = this.filters.searchKey;
            }
            getDrContractList(params).then((res) => {
                this.listLoading = false;
                this.dataPer = res.data.response.data;
                this.pages.dataCount = res.data.response.dataCount
                this.pages.pageIndex = res.data.response.pageIndex
                if(res.data.response.pageSize > 0){
                    this.pages.pageSize = res.data.response.pageSize
                }
            });
        },
        showInvoiceInfo(row){
            this.currentRow = row;
            this.invoiceVisible = true;
        },
        showExternalPerson(row){
            console.log(row);
            getContractContactUser({contractId: row.id}).then((res) => {
                this.externalPersonVisible = true;
                this.dataExternalPersons = res.data.response;
            });
        },
        showApplyRate(row){
            console.log(row);
            getContractApplyUser({contractId: row.id}).then((res) => {
                this.applyRatioVisible = true;
                this.dataApply = res.data.response;
            });
        },
        showAssociateRate(row){
            console.log(row);
            getContractCollaboratorUser({contractId: row.id}).then((res) => {
                this.assistanceRatioVisible = true;
                this.dataAssistance = res.data.response;
            });
        },
		callFunction(item) {
            if(item.func == "getListData") {
                this.pages.pageIndex = 1
            }
			this[item.func].apply(this, item);
		},
        // 表格数据分页条数累计
        indexMethod(index) {
            var currentPage = this.pages.pageIndex
            var pageSize = this.pages.pageSize
            return (index + 1) + (currentPage - 1) * pageSize
        },
	},
	mounted() {
        this.utils = util
		this.getListData();
		let routers = window.localStorage.router
		? JSON.parse(window.localStorage.router)
		: [];
		this.buttonList = getButtonList(this.$route.path, routers);
	}
};
</script>
<style lang="stylus" scoped>
.el-table>>>td, .el-table>>>th{
	padding: 8px!important;
}
</style>
